<template>
  <HeaderLoggedIn role="traveller" />
  <div class="main-wrapper flex-grow-1">
    <LeftbarLoggedIn role="traveller" />
    <div class="main-right-content" id="main-right">
      <!--            <Breadcrumb page="{{currentPage}}"/>-->
      <Breadcrumb role="traveller" />
      <div class="main-right-content-content container-fluid">
        <router-view />
      </div>
    </div>
  </div>
  <Footer v-if="page != 'search'" />
</template>

<script>
// import Vue from 'vue';
import { mapGetters } from 'vuex';
// import { defineAsyncComponent } from 'vue';
import CommonHandler from '../components/common/CommonHandler.js';

import HeaderLoggedIn from '../components/common/HeaderLoggedIn';
import Breadcrumb from '../components/common/bar/Breadcrumb';
import LeftbarLoggedIn from '../components/common/bar/LeftbarLoggedIn';
import Footer from '../components/common/Footer';

// function handleScrollHandler() {
//   var element = document.getElementById('topbar');
//
//   if (document.body.scrollTop > 75 || document.documentElement.scrollTop > 75) {
//     element.classList.add('bg-seeker');
//   } else {
//     element.classList.remove('bg-seeker');
//   }
// }
export default {
  name: 'TravellerLayout',
  props: ['role', 'page'],
  components: {
    Footer,
    HeaderLoggedIn,
    Breadcrumb,
    LeftbarLoggedIn,
  },
  watch: {
    currentPage: function () {
      return this.$router.currentRoute._rawValue.params.page;
    },
  },
  computed: {
    ...mapGetters({
      state: 'getState',
    }),
  },
  mounted() {
    document.body.classList.add('inner-page-topSpace');
    document.body.classList.add('d-flex');
    document.body.classList.add('flex-column');
    if (this.state.role == 'st') {
      // if (this.$props.role != this.state.currentRole) {
      // this.$store.commit('changeCurrentRole', this.$props.role);
      // }
    } else {
      //seeker don't have permission to access traveller
      // if (this.$props.role != this.state.currentRole) {
      // this.$store.commit('changeCurrentRole', this.$props.role)
      // }
    }
    window.addEventListener('scroll', CommonHandler.handleScroll);
  },
  unmounted() {
    document.body.classList.remove('inner-page-topSpace');
    document.body.classList.remove('d-flex');
    document.body.classList.remove('flex-column');
    window.removeEventListener('scroll', CommonHandler.handleScroll);
  },
};
</script>

<style lang="scss">
@import '../assets/scss/layout_logged_in.scss';
</style>
